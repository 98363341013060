import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';
import { DanhMucThanhVien } from 'src/app/shared/DanhMucThanhVien.model';
import { DanhMucThanhVienService } from 'src/app/shared/DanhMucThanhVien.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { ThanhVienPhanQuyenChucNang } from 'src/app/shared/ThanhVienPhanQuyenChucNang.model';
import { ThanhVienPhanQuyenChucNangService } from 'src/app/shared/ThanhVienPhanQuyenChucNang.service';
import { ThanhVienPhanQuyenKhuVuc } from 'src/app/shared/ThanhVienPhanQuyenKhuVuc.model';
import { ThanhVienPhanQuyenKhuVucService } from 'src/app/shared/ThanhVienPhanQuyenKhuVuc.service';

@Component({
  selector: 'app-thanh-vien-detail',
  templateUrl: './thanh-vien-detail.component.html',
  styleUrls: ['./thanh-vien-detail.component.css']
})
export class ThanhVienDetailComponent implements OnInit {

  @ViewChild('ThanhVienPhanQuyenChucNangSort') ThanhVienPhanQuyenChucNangSort: MatSort;
  @ViewChild('ThanhVienPhanQuyenChucNangPaginator') ThanhVienPhanQuyenChucNangPaginator: MatPaginator;
  @ViewChild('ThanhVienPhanQuyenKhuVucSort') ThanhVienPhanQuyenKhuVucSort: MatSort;
  @ViewChild('ThanhVienPhanQuyenKhuVucPaginator') ThanhVienPhanQuyenKhuVucPaginator: MatPaginator;

  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  activeAll: boolean = false;
  activeAllKhuVuc: boolean = false;
  danhMucTinhThanhID: number = environment.InitializationNumber;
  constructor(
    public dialogRef: MatDialogRef<ThanhVienDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,
    public DanhMucThanhVienService: DanhMucThanhVienService,

    public ThanhVienService: ThanhVienService,
    public ThanhVienPhanQuyenChucNangService: ThanhVienPhanQuyenChucNangService,
    public ThanhVienPhanQuyenKhuVucService: ThanhVienPhanQuyenKhuVucService,

  ) {
    this.DanhMucThanhVienGetToList();
    this.DanhMucTinhThanhGetToList();
    this.ThanhVienPhanQuyenChucNangSearch();
  }

  ngOnInit(): void {

  }

  ThanhVienSave() {
    this.NotificationService.warn(this.ThanhVienService.ComponentSaveForm());
  }

  Close() {
    this.dialogRef.close();
  }

  DanhMucThanhVienGetToList() {
    if (this.DanhMucThanhVienService.list.length == 0) {
      this.isShowLoading = true;
      this.DanhMucThanhVienService.GetAllToListAsync().subscribe(
        res => {
          this.DanhMucThanhVienService.list = (res as DanhMucXaPhuong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
  }

  DanhMucTinhThanhGetToList() {
    if (this.DanhMucTinhThanhService.list.length == 0) {
      this.isShowLoading = true;
      this.DanhMucTinhThanhService.baseParameter.Active = true;
      this.DanhMucTinhThanhService.GetByActiveToListAsync().subscribe(
        res => {
          this.DanhMucTinhThanhService.list = (res as DanhMucTinhThanh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.DanhMucTinhThanhService.list001 = this.DanhMucTinhThanhService.list;
          this.DanhMucQuanHuyenGetToList();
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
  }
  DanhMucQuanHuyenGetToList() {
    this.isShowLoading = true;
    this.DanhMucQuanHuyenService.baseParameter.ParentID = this.ThanhVienService.formData.DanhMucTinhThanhID;
    this.DanhMucQuanHuyenService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucQuanHuyenService.list = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucQuanHuyenService.list001 = this.DanhMucQuanHuyenService.list;
        this.DanhMucXaPhuongGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongGetToList() {
    this.isShowLoading = true;
    this.DanhMucXaPhuongService.baseParameter.ParentID = this.ThanhVienService.formData.DanhMucQuanHuyenID;
    this.DanhMucXaPhuongService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucXaPhuongService.list = (res as DanhMucXaPhuong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucXaPhuongService.list001 = this.DanhMucXaPhuongService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucTinhThanhFilter(searchString: string) {
    this.DanhMucTinhThanhService.Filter(searchString);
  }
  DanhMucQuanHuyenFilter(searchString: string) {
    this.DanhMucQuanHuyenService.Filter(searchString);
  }
  DanhMucXaPhuongFilter(searchString: string) {
    this.DanhMucXaPhuongService.Filter(searchString);
  }

  ThanhVienPhanQuyenChucNangSearch() {
    if (this.ThanhVienPhanQuyenChucNangService.SearchString.length > 0) {
      this.ThanhVienPhanQuyenChucNangService.DataSource.filter = this.ThanhVienPhanQuyenChucNangService.SearchString.toLowerCase();
    }
    else {
      this.ThanhVienPhanQuyenChucNangGetToList();
    }
  }

  ThanhVienPhanQuyenChucNangGetToList() {
    this.isShowLoading = true;
    this.ThanhVienPhanQuyenChucNangService.baseParameter.ParentID = this.ThanhVienService.formData.ID;
    this.ThanhVienPhanQuyenChucNangService.GetSQLByParentIDToListAsync().subscribe(
      res => {
        this.ThanhVienPhanQuyenChucNangService.list = (res as ThanhVienPhanQuyenChucNang[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ThanhVienPhanQuyenChucNangService.DataSource = new MatTableDataSource(this.ThanhVienPhanQuyenChucNangService.list);
        this.ThanhVienPhanQuyenChucNangService.DataSource.sort = this.ThanhVienPhanQuyenChucNangSort;
        this.ThanhVienPhanQuyenChucNangService.DataSource.paginator = this.ThanhVienPhanQuyenChucNangPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ThanhVienPhanQuyenChucNangActiveChange(element: ThanhVienPhanQuyenChucNang) {
    this.isShowLoading = true;
    this.ThanhVienPhanQuyenChucNangService.formData = element;
    this.ThanhVienPhanQuyenChucNangService.formData.ParentID = this.ThanhVienService.formData.ID;
    this.ThanhVienPhanQuyenChucNangService.SaveAsync().subscribe(
      res => {
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ThanhVienPhanQuyenChucNangActiveAllChange() {
    this.isShowLoading = true;
    for (let i = 0; i < this.ThanhVienPhanQuyenChucNangService.list.length; i++) {
      this.ThanhVienPhanQuyenChucNangService.formData = this.ThanhVienPhanQuyenChucNangService.list[i];
      this.ThanhVienPhanQuyenChucNangService.formData.ParentID = this.ThanhVienService.formData.ID;
      this.ThanhVienPhanQuyenChucNangService.formData.Active = this.activeAll;     
    }
    this.ThanhVienPhanQuyenChucNangService.SaveListAsync(this.ThanhVienPhanQuyenChucNangService.list).subscribe(
      res => {
        this.ThanhVienPhanQuyenChucNangSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );   
  }

  ThanhVienPhanQuyenKhuVucSearch() {


    if (this.ThanhVienPhanQuyenKhuVucService.SearchString.length > 0) {
      this.ThanhVienPhanQuyenKhuVucService.DataSource.filter = this.ThanhVienPhanQuyenKhuVucService.SearchString.toLowerCase();
      this.ThanhVienPhanQuyenKhuVucService.list000 = this.ThanhVienPhanQuyenKhuVucService.list.filter(item => item.Name.toLowerCase().includes(this.ThanhVienPhanQuyenKhuVucService.SearchString.toLowerCase()) || item.Code.toLowerCase().includes(this.ThanhVienPhanQuyenKhuVucService.SearchString.toLowerCase()));
    }
    else {
      this.ThanhVienPhanQuyenKhuVucGetToList();
    }
  }

  ThanhVienPhanQuyenKhuVucGetToList() {
    this.isShowLoading = true;
    this.ThanhVienPhanQuyenKhuVucService.baseParameter.ParentID = this.ThanhVienService.formData.ID;
    this.ThanhVienPhanQuyenKhuVucService.baseParameter.DanhMucTinhThanhID = this.danhMucTinhThanhID;
    this.ThanhVienPhanQuyenKhuVucService.GetSQLByParentIDAndDanhMucTinhThanhIDToListAsync().subscribe(
      res => {
        this.ThanhVienPhanQuyenKhuVucService.list = (res as ThanhVienPhanQuyenKhuVuc[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ThanhVienPhanQuyenKhuVucService.DataSource = new MatTableDataSource(this.ThanhVienPhanQuyenKhuVucService.list);
        this.ThanhVienPhanQuyenKhuVucService.DataSource.sort = this.ThanhVienPhanQuyenKhuVucSort;
        this.ThanhVienPhanQuyenKhuVucService.DataSource.paginator = this.ThanhVienPhanQuyenKhuVucPaginator;

        this.ThanhVienPhanQuyenKhuVucService.list000 = this.ThanhVienPhanQuyenKhuVucService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ThanhVienPhanQuyenKhuVucActiveChange(element: ThanhVienPhanQuyenKhuVuc) {
    this.isShowLoading = true;
    this.ThanhVienPhanQuyenKhuVucService.formData = element;
    this.ThanhVienPhanQuyenKhuVucService.formData.ParentID = this.ThanhVienService.formData.ID;
    this.ThanhVienPhanQuyenKhuVucService.SaveAsync().subscribe(
      res => {
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ThanhVienPhanQuyenKhuVucActiveAllChange() {
    this.isShowLoading = true;
    for (let i = 0; i < this.ThanhVienPhanQuyenKhuVucService.list000.length; i++) {
      this.ThanhVienPhanQuyenKhuVucService.formData = this.ThanhVienPhanQuyenKhuVucService.list000[i];
      this.ThanhVienPhanQuyenKhuVucService.formData.ParentID = this.ThanhVienService.formData.ID;
      this.ThanhVienPhanQuyenKhuVucService.formData.Active = this.activeAllKhuVuc;    
    }
    this.ThanhVienPhanQuyenKhuVucService.SaveListAsync(this.ThanhVienPhanQuyenKhuVucService.list).subscribe(
      res => {
        this.ThanhVienPhanQuyenKhuVucSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );      
  }

}

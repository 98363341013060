import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { DanhMucChucNang } from 'src/app/shared/DanhMucChucNang.model';
import { DanhMucChucNangService } from 'src/app/shared/DanhMucChucNang.service';
import { DanhMucThanhVien } from 'src/app/shared/DanhMucThanhVien.model';
import { DanhMucThanhVienService } from 'src/app/shared/DanhMucThanhVien.service';
import { DanhMucToChuc } from 'src/app/shared/DanhMucToChuc.model';
import { DanhMucToChucService } from 'src/app/shared/DanhMucToChuc.service';

@Component({
  selector: 'app-phan-quyen',
  templateUrl: './phan-quyen.component.html',
  styleUrls: ['./phan-quyen.component.css']
})
export class PhanQuyenComponent implements OnInit {

  @ViewChild('DanhMucChucNangSort') DanhMucChucNangSort: MatSort;
  @ViewChild('DanhMucChucNangPaginator') DanhMucChucNangPaginator: MatPaginator;

  @ViewChild('DanhMucThanhVienSort') DanhMucThanhVienSort: MatSort;
  @ViewChild('DanhMucThanhVienPaginator') DanhMucThanhVienPaginator: MatPaginator;


  @ViewChild('DanhMucToChucSort') DanhMucToChucSort: MatSort;
  @ViewChild('DanhMucToChucPaginator') DanhMucToChucPaginator: MatPaginator;


  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;

  constructor(
    private dialog: MatDialog,

    public NotificationService: NotificationService,

    public DanhMucChucNangService: DanhMucChucNangService,
    public DanhMucThanhVienService: DanhMucThanhVienService,
    public DanhMucToChucService: DanhMucToChucService,

  ) { }

  ngOnInit(): void {
    this.DanhMucChucNangSearch();
  }

  DanhMucChucNangSearch() {
    if (this.DanhMucChucNangService.SearchString.length > 0) {
      this.DanhMucChucNangService.DataSource.filter = this.DanhMucChucNangService.SearchString.toLowerCase();
    }
    else {
      this.DanhMucChucNangGetToList();
    }
  }
  DanhMucChucNangGetToList() {
    this.isShowLoading = true;
    this.DanhMucChucNangService.GetAllAndEmptyToListAsync().subscribe(
      res => {
        this.DanhMucChucNangService.list = (res as DanhMucChucNang[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucChucNangService.DataSource = new MatTableDataSource(this.DanhMucChucNangService.list);
        this.DanhMucChucNangService.DataSource.sort = this.DanhMucChucNangSort;
        this.DanhMucChucNangService.DataSource.paginator = this.DanhMucChucNangPaginator;

        this.DanhMucChucNangService.list001 = this.DanhMucChucNangService.list.slice(1);
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );    
  }
  DanhMucChucNangSave(element: DanhMucChucNang) {
    this.isShowLoading = true;
    this.DanhMucChucNangService.formData = element;
    this.DanhMucChucNangService.SaveAsync().subscribe(
      res => {
        this.DanhMucChucNangService.formData = res as DanhMucChucNang;
        this.DanhMucChucNangSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  DanhMucChucNangDelete(element: DanhMucChucNang) {
    if (confirm(environment.DeleteConfirm)) {
      this.isShowLoading = true;
      this.DanhMucChucNangService.baseParameter.ID = element.ID;
      this.DanhMucChucNangService.RemoveAsync().subscribe(
        res => {
          this.DanhMucChucNangSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
  }

  DanhMucThanhVienSearch() {
    this.DanhMucThanhVienService.SearchAll(this.DanhMucThanhVienSort, this.DanhMucThanhVienPaginator);
  }
  DanhMucThanhVienSave(element: DanhMucThanhVien) {
    this.DanhMucThanhVienService.formData = element;
    this.NotificationService.warn(this.DanhMucThanhVienService.ComponentSaveAll(this.DanhMucThanhVienSort, this.DanhMucThanhVienPaginator));
  }
  DanhMucThanhVienDelete(element: DanhMucThanhVien) {
    this.DanhMucThanhVienService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucThanhVienService.ComponentDeleteAll(this.DanhMucThanhVienSort, this.DanhMucThanhVienPaginator));
  }

  DanhMucToChucSearch() {
    this.DanhMucToChucService.SearchAll(this.DanhMucToChucSort, this.DanhMucToChucPaginator);
  }
  DanhMucToChucSave(element: DanhMucToChuc) {
    this.DanhMucToChucService.formData = element;
    this.NotificationService.warn(this.DanhMucToChucService.ComponentSaveAll(this.DanhMucToChucSort, this.DanhMucToChucPaginator));
  }
  DanhMucToChucDelete(element: DanhMucToChuc) {
    this.DanhMucToChucService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucToChucService.ComponentDeleteAll(this.DanhMucToChucSort, this.DanhMucToChucPaginator));
  }


}

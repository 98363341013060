import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CKEditorModule } from 'ngx-ckeditor';
import { ChartsModule } from 'ng2-charts';
import { CookieService } from 'ngx-cookie-service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MaterialModule } from './material/material.module';
import { GoogleMapsModule } from '@angular/google-maps';

import { NotificationService } from './shared/Notification.service';


import { AppComponent } from './app.component';
import { LoadingComponent } from './loading/loading.component';
import { HomepageComponent } from './homepage/homepage.component';
import { DanhMucComponent } from './danh-muc/danh-muc.component';
import { UploadComponent } from './upload/upload.component';
import { HopTacXaComponent } from './hop-tac-xa/hop-tac-xa.component';
import { ToChucDetailComponent } from './to-chuc-detail/to-chuc-detail.component';
import { DanhMucNguyenVatLieuComponent } from './danh-muc-nguyen-vat-lieu/danh-muc-nguyen-vat-lieu.component';
import { DoanhNghiepComponent } from './doanh-nghiep/doanh-nghiep.component';
import { ToChucQuyTrinhSanXuatComponent } from './to-chuc-quy-trinh-san-xuat/to-chuc-quy-trinh-san-xuat.component';
import { ToChucQuyTrinhSanXuatDetailComponent } from './to-chuc-quy-trinh-san-xuat-detail/to-chuc-quy-trinh-san-xuat-detail.component';
import { ReportComponent } from './report/report.component';
import { ThanhVienComponent } from './thanh-vien/thanh-vien.component';
import { ThanhVienNongDanDetailComponent } from './thanh-vien-nong-dan-detail/thanh-vien-nong-dan-detail.component';
import { ThanhVienCoQuanQuanLyDetailComponent } from './thanh-vien-co-quan-quan-ly-detail/thanh-vien-co-quan-quan-ly-detail.component';
import { NongDanComponent } from './nong-dan/nong-dan.component';
import { ThoiTietComponent } from './thoi-tiet/thoi-tiet.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MapComponent } from './map/map.component';
import { ToChucInfoComponent } from './to-chuc-info/to-chuc-info.component';
import { MapVungTrongComponent } from './map-vung-trong/map-vung-trong.component';
import { MapVungTrongInfoComponent } from './map-vung-trong-info/map-vung-trong-info.component';
import { MapToChucToaDoComponent } from './map-to-chuc-toa-do/map-to-chuc-toa-do.component';
import { ThongBaoComponent } from './thong-bao/thong-bao.component';
import { ThongBaoDetailComponent } from './thong-bao-detail/thong-bao-detail.component';
import { GioiThieuComponent } from './gioi-thieu/gioi-thieu.component';
import { DanhMucSanPhamGiongDetailComponent } from './danh-muc-san-pham-giong-detail/danh-muc-san-pham-giong-detail.component';
import { BaiVietDetailComponent } from './bai-viet-detail/bai-viet-detail.component';
import { HaTangKyThuatDetailComponent } from './ha-tang-ky-thuat-detail/ha-tang-ky-thuat-detail.component';
import { DanhMucTinhThanhToaDoDetailComponent } from './danh-muc-tinh-thanh-toa-do-detail/danh-muc-tinh-thanh-toa-do-detail.component';
import { ThongKeComponent } from './thong-ke/thong-ke.component';
import { MapHopTacXaComponent } from './map-hop-tac-xa/map-hop-tac-xa.component';
import { TuVanComponent } from './tu-van/tu-van.component';
import { HuongDanSuDungComponent } from './huong-dan-su-dung/huong-dan-su-dung.component';
import { PhanQuyenComponent } from './phan-quyen/phan-quyen.component';
import { ThanhVienPhanQuyenChucNangComponent } from './thanh-vien-phan-quyen-chuc-nang/thanh-vien-phan-quyen-chuc-nang.component';
import { ThanhVienPhanQuyenKhuVucComponent } from './thanh-vien-phan-quyen-khu-vuc/thanh-vien-phan-quyen-khu-vuc.component';
import { QuanLyComponent } from './quan-ly/quan-ly.component';
import { ThanhVienDetailComponent } from './thanh-vien-detail/thanh-vien-detail.component';
import { ThongKe001Component } from './thong-ke001/thong-ke001.component';
import { DownloadComponent } from './download/download.component';
import { ChuyenGiaComponent } from './chuyen-gia/chuyen-gia.component';
import { TaiLieuComponent } from './tai-lieu/tai-lieu.component';
import { ThanhVienThongTinComponent } from './thanh-vien-thong-tin/thanh-vien-thong-tin.component';



@NgModule({
    declarations: [
        AppComponent,
        LoadingComponent,
        HomepageComponent,
        DanhMucComponent,
        UploadComponent,
        HopTacXaComponent,
        ToChucDetailComponent,
        DanhMucNguyenVatLieuComponent,
        DoanhNghiepComponent,
        ToChucQuyTrinhSanXuatComponent,
        ToChucQuyTrinhSanXuatDetailComponent,
        ReportComponent,
        ThanhVienComponent,
        ThanhVienNongDanDetailComponent,
        ThanhVienCoQuanQuanLyDetailComponent,
        NongDanComponent,
        ThoiTietComponent,
        DashboardComponent,
        MapComponent,
        ToChucInfoComponent,        
        MapVungTrongComponent, MapVungTrongInfoComponent, MapToChucToaDoComponent, ThongBaoComponent, ThongBaoDetailComponent, GioiThieuComponent, DanhMucSanPhamGiongDetailComponent, BaiVietDetailComponent, HaTangKyThuatDetailComponent, DanhMucTinhThanhToaDoDetailComponent, ThongKeComponent, MapHopTacXaComponent, TuVanComponent, HuongDanSuDungComponent, PhanQuyenComponent, ThanhVienPhanQuyenChucNangComponent, ThanhVienPhanQuyenKhuVucComponent, QuanLyComponent, ThanhVienDetailComponent, ThongKe001Component, DownloadComponent, ChuyenGiaComponent, TaiLieuComponent, ThanhVienThongTinComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        FormsModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        GoogleMapsModule,
        ChartsModule,
        CKEditorModule,
    ],
    providers: [
        CookieService,
        NotificationService,
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { DownloadService } from 'src/app/shared/Download.service';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { ThanhVienToken } from 'src/app/shared/ThanhVienToken.model';
import { ThanhVienTokenService } from 'src/app/shared/ThanhVienToken.service';
import { ThanhVienLichSuTruyCap } from 'src/app/shared/ThanhVienLichSuTruyCap.model';
import { ThanhVienLichSuTruyCapService } from 'src/app/shared/ThanhVienLichSuTruyCap.service';

import { DanhMucChucNang } from 'src/app/shared/DanhMucChucNang.model';
import { DanhMucChucNangService } from 'src/app/shared/DanhMucChucNang.service';


import { interval } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'CRM';
  domainName = environment.DomainDestination;
  domainURL = environment.DomainURL;
  queryString: string = environment.InitializationString;
  queryStringSub: string = environment.InitializationString;
  constructor(
    public router: Router,

    public DownloadService: DownloadService,
    public DanhMucChucNangService: DanhMucChucNangService,

    public ThanhVienService: ThanhVienService,
    public ThanhVienTokenService: ThanhVienTokenService,
    public ThanhVienLichSuTruyCapService: ThanhVienLichSuTruyCapService,

  ) {
    this.getByQueryString();
  }
  getByQueryString() {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.queryString = event.url;
        if (this.queryString.indexOf(environment.Token) > -1) {
          localStorage.setItem(environment.Token, this.queryString.split('=')[this.queryString.split('=').length - 1]);
        }
        this.AuthenticationToken();
      }
    });
  }
  AuthenticationToken() {
    let token = localStorage.getItem(environment.Token);
    let isLogin = true;
    if (token == null) {
      isLogin = false;
    }
    else {
      this.ThanhVienTokenService.baseParameter.Token = token;
      this.ThanhVienTokenService.AuthenticationByTokenAsync().subscribe(
        res => {
          this.ThanhVienTokenService.formData = res as ThanhVienToken;
          if (this.ThanhVienTokenService.formData != null) {
            if (this.ThanhVienTokenService.formData.ParentID > 0) {
              this.ThanhVienService.baseParameter.ID = this.ThanhVienTokenService.formData.ParentID;
              this.ThanhVienService.GetByIDAsync().subscribe(
                res => {
                  this.ThanhVienService.formDataLogin = res as ThanhVien;
                  if (this.ThanhVienService.formDataLogin) {
                    if (this.ThanhVienService.formDataLogin.ParentID == null) {
                      this.ThanhVienService.formDataLogin.ParentID = environment.InitializationNumber;
                    }
                    localStorage.setItem(environment.ThanhVienID, this.ThanhVienService.formDataLogin.ID.toString());
                    localStorage.setItem(environment.ThanhVienParentID, this.ThanhVienService.formDataLogin.ParentID.toString());
                    localStorage.setItem(environment.ThanhVienTaiKhoan, this.ThanhVienService.formDataLogin.TaiKhoan);
                    localStorage.setItem(environment.ThanhVienHoTen, this.ThanhVienService.formDataLogin.Name);
                    localStorage.setItem(environment.ThanhVienFileName, this.ThanhVienService.formDataLogin.FileName);
                    this.DanhMucChucNangGetByThanhVienIDToListAsync();
                    this.ThanhVienLichSuTruyCapSaveAsync(this.queryString);
                  }
                  else {
                    isLogin = false;
                  }
                },
                err => {
                  isLogin = false;
                }
              );
            }
            else {
              isLogin = false;
            }
          }
          else {
            isLogin = false;
          }
        },
        err => {
          isLogin = false;
        }
      );
    }
    if (isLogin == false) {
      let destinationURL = environment.LoginURL + "?url=" + environment.DomainURL;
      window.location.href = destinationURL;
    }
  }
  DanhMucChucNangGetByThanhVienIDToListAsync() {
    if (this.queryString) {
      if (this.queryString.length > 0) {
        this.queryStringSub = this.queryString.substring(1, this.queryString.length);
      }
    }
    this.DanhMucChucNangService.GetSQLByThanhVienIDAndActiveToListAsync().subscribe(
      res => {
        this.DanhMucChucNangService.ListChild = (res as DanhMucChucNang[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucChucNangService.ListParent = [];
        let isLogin = false;
        for (var i = 0; i < this.DanhMucChucNangService.ListChild.length; i++) {
          if (this.queryStringSub == this.DanhMucChucNangService.ListChild[i].Code) {
            this.DanhMucChucNangService.ListChild[i].Active = true;
          }
          else {
            this.DanhMucChucNangService.ListChild[i].Active = false;
          }
          if (this.queryStringSub.indexOf(this.DanhMucChucNangService.ListChild[i].Code) > -1) {
            isLogin = true;
          }
          if (this.DanhMucChucNangService.ListChild[i].Code.indexOf(environment.HTMLExtension) > -1) {
            this.DanhMucChucNangService.ListChild[i].Code = environment.DomainURL + this.DanhMucChucNangService.ListChild[i].Code + "?" + environment.Token + "=" + this.ThanhVienTokenService.baseParameter.Token;
          }
          else {
            this.DanhMucChucNangService.ListChild[i].Code = environment.DomainDestination + this.DanhMucChucNangService.ListChild[i].Code;
          }
        }
        for (var i = 0; i < this.DanhMucChucNangService.ListChild.length; i++) {
          if (this.DanhMucChucNangService.ListChild[i].ParentID == 0) {
            this.DanhMucChucNangService.ListChild[i].Active = false;
            this.DanhMucChucNangService.ListChild[i].ListChild = [];
            for (var j = 0; j < this.DanhMucChucNangService.ListChild.length; j++) {
              if (this.DanhMucChucNangService.ListChild[i].ID == this.DanhMucChucNangService.ListChild[j].ParentID) {
                this.DanhMucChucNangService.ListChild[i].ListChild.push(this.DanhMucChucNangService.ListChild[j]);
                if (this.DanhMucChucNangService.ListChild[j].Active == true) {
                  this.DanhMucChucNangService.ListChild[i].Active = true;
                }
              }
            }
            this.DanhMucChucNangService.ListParent.push(this.DanhMucChucNangService.ListChild[i]);
          }
        }

        if (this.queryStringSub.indexOf("ThanhVienThongBao") > -1) {
          isLogin = true;
        }
        if (this.queryStringSub.indexOf("ThanhVienThongTin") > -1) {
          isLogin = true;
        }
        if (this.queryStringSub.indexOf("ThanhVienQuenMatKhau") > -1) {
          isLogin = true;
        }
        if (this.queryStringSub.indexOf("Info") > -1) {
          isLogin = true;
        }
        if (isLogin == false) {
          let destinationURL = environment.DomainDestination + environment.Homepage;
          window.location.href = destinationURL;
        }
      },
      err => {
      }
    );
  }

  ThanhVienLichSuTruyCapSaveAsync(url: string) {
    url = environment.DomainURL + "#" + url;
    this.ThanhVienLichSuTruyCapService.formData.URL = url;
    this.ThanhVienLichSuTruyCapService.formData.Name = this.ThanhVienService.formDataLogin.Name;
    this.ThanhVienLichSuTruyCapService.formData.Code = this.ThanhVienService.formDataLogin.TaiKhoan;
    this.ThanhVienLichSuTruyCapService.formData.Token = localStorage.getItem(environment.Token);
    this.ThanhVienLichSuTruyCapService.formData.ParentID = this.ThanhVienService.formDataLogin.ID;
    this.DownloadService.GetIPAddress().then(res => {
      this.DownloadService.IPAddress = res["ip"];
      this.ThanhVienLichSuTruyCapService.formData.Description = this.DownloadService.IPAddress;
      this.ThanhVienLichSuTruyCapService.SaveAsync().subscribe(
        res => {
        },
        err => {
        }
      );
    }).catch(error => {
      this.ThanhVienLichSuTruyCapService.SaveAsync().subscribe(
        res => {
        },
        err => {
        }
      );
    });
  }

  MenuClick(itemParent: DanhMucChucNang) {
    for (var i = 0; i < this.DanhMucChucNangService.ListParent.length; i++) {
      this.DanhMucChucNangService.ListParent[i].Active = false;
      if (this.DanhMucChucNangService.ListParent[i].ID == itemParent.ID) {
        this.DanhMucChucNangService.ListParent[i].Active = true;
      }
    }
  }
  Logout() {
    localStorage.setItem(environment.Token, environment.InitializationString);
    localStorage.setItem(environment.ThanhVienID, environment.InitializationString);    
    window.location.href = environment.LoginURL;
  }
}

<nav id="sidebar" class="sidebar-wrapper">
      <div class="app-brand p-3 mb-3">
            <a href="#" *ngIf="ThanhVienService.formDataLogin && ThanhVienService.formDataLogin.Name">
                  <img src="/assets/image/logo.png" class="logo"
                        alt="Trục dữ liệu lớn Nông nghiệp"
                        title="Trục dữ liệu lớn Nông nghiệp" /></a>
      </div>
      <div class="sidebarMenuScroll">
            <ul class="sidebar-menu">
                  <li (click)="MenuClick(itemParent)"
                        [ngClass]="itemParent.Active == true ? 'treeview active current-page': 'treeview'"
                        *ngFor="let itemParent of DanhMucChucNangService.ListParent;">
                        <a style="cursor: pointer;">
                              <i class="{{itemParent.Display}}"></i>
                              <span class="menu-text">{{itemParent.Name}}</span>
                        </a>
                        <ul [ngClass]="itemParent.Active == true ? 'treeview-menu menu-open': 'treeview-menu'">
                              <li *ngFor="let itemChild of itemParent.ListChild;">
                                    <a [ngStyle]="{'font-weight': itemChild.Active == true ? 'bold' : ''}"
                                          [ngClass]="itemChild.Active == true ? 'active-sub': ''"
                                          href="{{itemChild.Code}}">{{itemChild.Name}}</a>
                              </li>
                        </ul>
                  </li>
                  <li>
                        <a href="{{domainName}}ThanhVienThongTin">
                              <i class="bi bi-shield-lock"></i>
                              <span class="menu-text">Đổi mật khẩu</span>
                        </a>
                  </li>
                  <li>
                        <a style="cursor: pointer;" (click)="Logout()">
                              <i class="bi bi-power"></i>
                              <span class="menu-text">Đăng xuất</span>
                        </a>
                  </li>
            </ul>
      </div>     
</nav>
<div class="app-container">
      <div class="app-header d-flex align-items-center">
            <div class="d-flex">
                  <button class="pin-sidebar">
                        <i class="bi bi-list lh-1"></i>
                  </button>
            </div>
            <div class="app-brand-sm d-lg-none d-flex">                
            </div>
            <div class="search-container d-lg-block d-none mx-3">
                  <input type="text" class="form-control"
                        placeholder="Trục dữ liệu lớn Nông nghiệp | Hệ sinh thái Vùng trồng | Mô hình thí điểm DGK" />
            </div>
            <div class="header-actions">
                  <div class="dropdown ms-4">
                        <a id="userSettings" class="dropdown-toggle d-flex py-2 align-items-center" href="#!"
                              role="button" data-bs-toggle="dropdown" aria-expanded="false">                              
                              <span class="ms-2 text-truncate d-lg-block d-none"
                                    *ngIf="ThanhVienService.formDataLogin && ThanhVienService.formDataLogin.Name">{{ThanhVienService.formDataLogin.Name}}</span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end shadow-lg">                            
                              <a class="dropdown-item d-flex align-items-center" href="#"><i
                                          class="bi bi-shield-lock fs-4 me-2"></i>Đổi mật khẩu</a>
                              <div class="mx-3 mt-2 d-grid">
                                    <a style="cursor: pointer;" class="btn btn-primary">Đăng xuất</a>
                              </div>
                        </div>
                  </div>
                  <div class="d-flex">
                        <button class="toggle-sidebar">
                              <i class="bi bi-list lh-1"></i>
                        </button>
                  </div>
            </div>
      </div>
      <hr />
      <router-outlet></router-outlet>
</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';

import { ThanhVienPhanQuyenKhuVuc } from 'src/app/shared/ThanhVienPhanQuyenKhuVuc.model';
import { ThanhVienPhanQuyenKhuVucService } from 'src/app/shared/ThanhVienPhanQuyenKhuVuc.service';



@Component({
  selector: 'app-thanh-vien-phan-quyen-khu-vuc',
  templateUrl: './thanh-vien-phan-quyen-khu-vuc.component.html',
  styleUrls: ['./thanh-vien-phan-quyen-khu-vuc.component.css']
})
export class ThanhVienPhanQuyenKhuVucComponent implements OnInit {

  @ViewChild('ThanhVienPhanQuyenKhuVucSort') ThanhVienPhanQuyenKhuVucSort: MatSort;
  @ViewChild('ThanhVienPhanQuyenKhuVucPaginator') ThanhVienPhanQuyenKhuVucPaginator: MatPaginator;

  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  parentID: number = environment.InitializationNumber;
  danhMucTinhThanhID: number = environment.InitializationNumber;
  activeAll: boolean = false;

  constructor(
    public NotificationService: NotificationService,

    public DanhMucTinhThanhService: DanhMucTinhThanhService,

    public ThanhVienService: ThanhVienService,
    public ThanhVienPhanQuyenKhuVucService: ThanhVienPhanQuyenKhuVucService,
  ) { }

  ngOnInit(): void {
    this.ThanhVienGetToList();
    this.DanhMucTinhThanhGetToList();
  }

  DanhMucTinhThanhGetToList() {
    this.isShowLoading = true;
    this.DanhMucTinhThanhService.baseParameter.Active = true;
    this.DanhMucTinhThanhService.GetByActiveToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.list = (res as DanhMucTinhThanh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucTinhThanhService.list001 = this.DanhMucTinhThanhService.list;
        if (this.DanhMucTinhThanhService.list) {
          if (this.DanhMucTinhThanhService.list.length) {
            this.danhMucTinhThanhID = this.DanhMucTinhThanhService.list[0].ID;
          }
        }
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucTinhThanhFilter(searchString: string) {
    this.DanhMucTinhThanhService.Filter(searchString);
  }

  ThanhVienGetToList() {
    this.isShowLoading = true;
    this.ThanhVienService.GetAllToListAsync().subscribe(
      res => {
        this.ThanhVienService.list = (res as ThanhVien[]).sort((a, b) => (a.ParentID > b.ParentID ? 1 : -1));
        this.ThanhVienService.list001 = this.ThanhVienService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ThanhVienFilter(searchString: string) {
    this.ThanhVienService.Filter(searchString);
  }
  ThanhVienPhanQuyenKhuVucSearch() {
    //this.activeAll = false;
    if (this.ThanhVienPhanQuyenKhuVucService.SearchString.length > 0) {
      this.ThanhVienPhanQuyenKhuVucService.DataSource.filter = this.ThanhVienPhanQuyenKhuVucService.SearchString.toLowerCase();
      this.ThanhVienPhanQuyenKhuVucService.list000 = this.ThanhVienPhanQuyenKhuVucService.list.filter(item => item.Name.toLowerCase().includes(this.ThanhVienPhanQuyenKhuVucService.SearchString.toLowerCase()) || item.Code.toLowerCase().includes(this.ThanhVienPhanQuyenKhuVucService.SearchString.toLowerCase()));
    }
    else {
      this.ThanhVienPhanQuyenKhuVucGetToList();
    }
  }

  ThanhVienPhanQuyenKhuVucGetToList() {
    this.isShowLoading = true;
    this.ThanhVienPhanQuyenKhuVucService.baseParameter.ParentID = this.parentID;
    this.ThanhVienPhanQuyenKhuVucService.baseParameter.DanhMucTinhThanhID = this.danhMucTinhThanhID;
    this.ThanhVienPhanQuyenKhuVucService.GetSQLByParentIDAndDanhMucTinhThanhIDToListAsync().subscribe(
      res => {
        this.ThanhVienPhanQuyenKhuVucService.list = (res as ThanhVienPhanQuyenKhuVuc[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ThanhVienPhanQuyenKhuVucService.DataSource = new MatTableDataSource(this.ThanhVienPhanQuyenKhuVucService.list);
        this.ThanhVienPhanQuyenKhuVucService.DataSource.sort = this.ThanhVienPhanQuyenKhuVucSort;
        this.ThanhVienPhanQuyenKhuVucService.DataSource.paginator = this.ThanhVienPhanQuyenKhuVucPaginator;

        this.ThanhVienPhanQuyenKhuVucService.list000 = this.ThanhVienPhanQuyenKhuVucService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ThanhVienPhanQuyenKhuVucActiveChange(element: ThanhVienPhanQuyenKhuVuc) {
    this.isShowLoading = true;
    this.ThanhVienPhanQuyenKhuVucService.formData = element;
    this.ThanhVienPhanQuyenKhuVucService.formData.ParentID = this.parentID;
    this.ThanhVienPhanQuyenKhuVucService.SaveAsync().subscribe(
      res => {
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ThanhVienPhanQuyenKhuVucActiveAllChange() {
    this.isShowLoading = true;
    for (let i = 0; i < this.ThanhVienPhanQuyenKhuVucService.list000.length; i++) {
      this.ThanhVienPhanQuyenKhuVucService.formData = this.ThanhVienPhanQuyenKhuVucService.list000[i];
      this.ThanhVienPhanQuyenKhuVucService.formData.ParentID = this.parentID;
      this.ThanhVienPhanQuyenKhuVucService.formData.Active = this.activeAll;     
    }
    this.ThanhVienPhanQuyenKhuVucService.SaveListAsync(this.ThanhVienPhanQuyenKhuVucService.list).subscribe(
      res => {
        this.ThanhVienPhanQuyenKhuVucSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );          
  }
}
<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-bar-chart fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Dashboard</h2>
            <h6 class="m-0 text-dark fw-light">
                Báo cáo thống kê
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
            <!-- <a class="link-primary" href="{{domainName}}Report" title="Báo cáo Thống kê">
                <b>Báo cáo Thống kê</b>
            </a> |
            <a class="link-primary" href="{{domainName}}Map" title="1.1 Bản đồ">
                <b>1.1 Bản đồ</b>
            </a> |
            <a class="link-primary" href="{{domainName}}HopTacXa" title="2.1 Hợp tác xã">
                <b>2.1 Hợp tác xã</b>
            </a> |
            <a class="link-primary" href="{{domainName}}ToChucQuyTrinhSanXuat" title="2.2 Năng lực sản xuất">
                <b>2.2 Năng lực sản xuất</b>
            </a> -->
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-2 card-info">
                <div class="card-header">
                    <h5 class="card-title text-white">Hợp tác xã đăng ký</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class=" text-white">
                            <h2 class="display-6 mb-2 fw-semibold">{{ReportService.formData.SoLuongThamGia |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75">Chi tiết đến xã phường</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-2 card-danger">
                <div class="card-header">
                    <h5 class="card-title text-white">Hợp tác xã khảo sát</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class=" text-white">
                            <h2 class="display-6 mb-2 fw-semibold">{{ReportService.formData.SoLuongKhaoSat |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75">Khảo sát sản xuất</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-2 card-success">
                <div class="card-header">
                    <h5 class="card-title text-white">Diện tích đăng ký</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class=" text-white">
                            <h2 class="display-6 mb-2 fw-semibold">> {{ReportService.formData.DienTichThamGia |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75">Đơn vị tính: ha</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-2 card-warning">
                <div class="card-header">
                    <h5 class="card-title text-white">Diện tích khảo sát</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class="text-white">
                            <h2 class="display-6 mb-2 fw-semibold">> {{ReportService.formData.DienTichKhaoSat |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75">Đơn vị tính: ha</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-2 card-primary">
                <div class="card-header">
                    <h5 class="card-title text-white bg-primary">Vùng trồng khảo sát</h5>
                </div>
                <div class="card-body bg-primary">
                    <div class="d-flex justify-content-between">
                        <div class="text-white">
                            <h2 class="display-6 mb-2 fw-semibold">{{ReportService.formData.VungTrongKhaoSat |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75">Tọa độ HTX cung cấp</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-2 card-dark">
                <div class="card-header">
                    <h5 class="card-title text-white">Nông dân</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class="text-white">
                            <h2 class="display-6 mb-2 fw-semibold">{{ReportService.formData.ThanhVienThamGia |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75">Hội viên tham gia HTX</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row gx-4">
        <div class="col-lg-6 col-sm-12 col-12">
            <h3 class="text-center" style="text-transform: uppercase;">Số lượng Hợp tác xã đã khảo sát</h3>
            <canvas style="font-size: 30px;" baseChart [datasets]="ChartDataReport1002SoLuongKhaoSat"
                [labels]="ChartLabelsReport1002SoLuongKhaoSat" [chartType]="ChartTypeReport1002SoLuongKhaoSat"
                [colors]="ChartColorsReport1002SoLuongKhaoSat" [options]="ChartOptionsReport1002SoLuongKhaoSat">
            </canvas>
        </div>
        <div class="col-lg-6 col-sm-12 col-12">
            <h3 class="text-center" style="text-transform: uppercase;">Diện tích Hợp tác xã đã khảo sát</h3>
            <canvas style="font-size: 30px;" baseChart [datasets]="ChartDataReport1002DienTichKhaoSat"
                [labels]="ChartLabelsReport1002DienTichKhaoSat" [chartType]="ChartTypeReport1002DienTichKhaoSat"
                [colors]="ChartColorsReport1002DienTichKhaoSat" [options]="ChartOptionsReport1002DienTichKhaoSat">
            </canvas>
        </div>
    </div>
    <br />
    <div class="row gx-4">
        <div class="col-lg-6 col-sm-12 col-12">
            <h3 class="text-center" style="text-transform: uppercase;">Số lượng và Diện tích Hợp tác xã đã khảo sát</h3>
            <canvas style="font-size: 30px;" baseChart [datasets]="ChartDataReport1002" [labels]="ChartLabelsReport1002"
                [chartType]="ChartTypeReport1002" [colors]="ChartColorsReport1002" [options]="ChartOptionsReport1002">
            </canvas>
        </div>
        <div class="col-lg-6 col-sm-12 col-12">
            <h3 class="text-center" style="text-transform: uppercase;">Số lượng và Diện tích Hợp tác xã đã khảo sát</h3>
            <div class="table-responsive">
                <table class="table m-0 align-middle">
                    <thead>
                        <tr>
                            <th style="text-align: center;">STT</th>
                            <th style="text-align: center;">Tỉnh thành</th>
                            <th style="text-align: center;">Số lượng khảo sát</th>
                            <th style="text-align: center;">Diện tích khảo sát (ha)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of ReportService.list1002; index as i;">
                            <td style="text-align: center;">
                                {{i + 1}}
                            </td>
                            <td>
                                <b>{{item.DanhMucTinhThanhName}}</b>
                            </td>
                            <td style="text-align: center;">
                                {{item.SoLuongKhaoSat |
                                number:
                                '1.0-0'}}
                            </td>
                            <td style="text-align: center;">
                                {{item.DienTichKhaoSat |
                                number:
                                '1.0-2'}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <br />
    <div class="row gx-4">
        <div class="col-lg-6 col-sm-12 col-12">
            <h3 class="text-center" style="text-transform: uppercase;">DỰ BÁO NHIỆT ĐỘ</h3>
            <canvas baseChart [datasets]="ChartDataReportThoiTiet0001NhietDo"
                [labels]="ChartLabelsReportThoiTiet0001NhietDo" [chartType]="ChartTypeReportThoiTiet0001NhietDo"
                [colors]="ChartColorsReportThoiTiet0001NhietDo" [options]="ChartOptionsReportThoiTiet0001NhietDo">
            </canvas>
            <h3 class="text-center" style="text-transform: uppercase;">LỊCH SỬ NHIỆT ĐỘ</h3>
            <canvas baseChart [datasets]="ChartDataReportThoiTiet0002NhietDo"
                [labels]="ChartLabelsReportThoiTiet0002NhietDo" [chartType]="ChartTypeReportThoiTiet0002NhietDo"
                [colors]="ChartColorsReportThoiTiet0002NhietDo" [options]="ChartOptionsReportThoiTiet0002NhietDo">
            </canvas>
        </div>
        <div class="col-lg-6 col-sm-12 col-12">
            <h3 class="text-center" style="text-transform: uppercase;">DỰ BÁO ĐỘ ẨM</h3>
            <canvas baseChart [datasets]="ChartDataReportThoiTiet0001DoAm" [labels]="ChartLabelsReportThoiTiet0001DoAm"
                [chartType]="ChartTypeReportThoiTiet0001DoAm" [colors]="ChartColorsReportThoiTiet0001DoAm"
                [options]="ChartOptionsReportThoiTiet0001DoAm">
            </canvas>
            <h3 class="text-center" style="text-transform: uppercase;">LỊCH SỬ ĐỘ ẨM</h3>
            <canvas baseChart [datasets]="ChartDataReportThoiTiet0002DoAm" [labels]="ChartLabelsReportThoiTiet0002DoAm"
                [chartType]="ChartTypeReportThoiTiet0002DoAm" [colors]="ChartColorsReportThoiTiet0002DoAm"
                [options]="ChartOptionsReportThoiTiet0002DoAm">
            </canvas>
        </div>
    </div>
    <br />
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12">
            <h3 class="text-center" style="text-transform: uppercase; color: red;">Đánh giá tổng quát tính sẳn sàng của các HTX đăng ký tham gia đề án
            </h3>
            <div class="table-responsive">
                <table class="table m-0 align-middle">
                    <thead> 
                        <tr>
                            <th style="text-align: center; background-color: green; color: #ffffff;" rowspan="2">STT</th>
                            <th style="text-align: center; background-color: green; color: #ffffff;" rowspan="2">Tiêu chí đề án</th>
                            <th style="text-align: center; background-color: green; color: #ffffff;" rowspan="2">Đến năm 2025</th>
                            <th style="text-align: center; background-color: green; color: #ffffff;" rowspan="2">Đến năm 2030</th>
                            <th style="text-align: center; background-color: green; color: #ffffff;" rowspan="2">Đánh giá tính sẵn sàng của vùng nguyên liệu đăng ký tham gia đề án</th>
                            <th style="text-align: center; background-color: green; color: #ffffff;" colspan="3">Đánh giá chung so với các tiêu chí</th>
                        </tr>
                        <tr>                            
                            <th style="text-align: center; background-color: green; color: #ffffff;">Hiện trạng</th>
                            <th style="text-align: center; background-color: green; color: #ffffff;">Mức độ đạt theo tiêu chí</th>
                            <th style="text-align: center; background-color: green; color: #ffffff;">Giải pháp khắc phục</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Lúa giống gieo sạ</td>
                            <td>80-100kg/ha
                            </td>
                            <td>Dưới 70kg/ha
                            </td>
                            <td style="font-weight: bold;">43,96% đạt dưới 100kg/ha, 9,62% đạt dưới 80kg
                            </td>
                            <td>Có 46,2 % HTX chưa tuân thủ giảm lượng gieo sạ ổn định trên các vụ lúa/năm; có vụ gieo sạ giống trên 100 ký/ ha
                            </td>
                            <td style="color: red; background-color: yellow;">Chưa đạt</td>
                            <td>Rà soát quy trình đề xuất giải pháp quản lý rũi ro thiếu giống cấy giậm do tác động bất lợi của biến đổi khí hậu (ngập lũ cục bộ, sinh vật gây hại…). Hỗ trợ hoàn thiện cơ sở hạ tầng, thuỷ lợi nội đồng cho các vùng VnSAT mở rộng để hạn chế rũi ro trong sản xuất</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Giảm lượng phân bón hóa học</td>
                            <td>20%
                            </td>
                            <td>20%
                            </td>
                            <td style="font-weight: bold;">Giảm từ 20-30% lượng phân bón
                            </td>
                            <td>Có 67,93% HTX chỉ bón phân 1-3 lần cho một vụ sản xuất và 30,71% HTX bón phân 4-5 lần cho vụ sản xuất. Như vậy, hiện tại các HTX đã chủ động giảm từ 1 đến 2 lần bón phân cho 1 vụ sản xuất. Từ đó, lượng phân bón đã được giảm đi từ 20 đến 30% cho một vụ sản xuất. Bên cạnh đó, sử dụng thiết bị cơ giới bón phân thay thế bón bằng tay như: bón vùi phân bằng máy bắn phân/Drone…</td>
                            <td>Đạt</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Giảm lượng nước tưới</td>
                            <td>20%
                            </td>
                            <td>20%
                            </td>
                            <td style="font-weight: bold;">Giảm 20% lượng nước tưới
                            </td>
                            <td>Có 100% HTX thực hiện giảm nước tưới (Áp dụng tưới ngập khô xen kẽ)</td>
                            <td>Đạt</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>100% diện tích áp dụng quy trình canh tác bền vững
                            </td>
                            <td></td>
                            <td></td>
                            <td style="font-weight: bold;">Có 90,38% HTX điều tra có áp dụng quy trình canh tác bền vững
                            </td>
                            <td>9,62% số HTX chưa tuân thủ hoàn toàn quy trình canh tác bền vững</td>
                            <td style="color: red; background-color: yellow;">Chưa đạt
                            </td>
                            <td>Tiếp tục đào tạo, bồi dưỡng quy trình canh tác; hướng dẫn hỗ trợ HTX tổ chức sản xuất theo quy trình canh tác bền vững</td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>Diện tích sản xuất vùng chuyên canh có liên kết giữa DN với tổ hợp tác, HTX
                            </td>
                            <td>100%
                            </td>
                            <td></td>
                            <td style="font-weight: bold;">50,09% tỷ lệ diện tích canh tác có thực hiện tham gia vào các hình thức liên kết 
                            </td>
                            <td>49,91% chưa tham gia vào các hình thức  liên kết
                            </td>
                            <td style="color: red; background-color: yellow;">Chưa đạt
                            </td>
                            <td>Đào tạo, bồi dưỡng kiến thức về xây dựng chuỗi giá trị lúa gạo, tư vấn hướng dẫn xây dựng các dự án/kế hoạch liên kết; tuyên truyền, tham quan học tập mô hình</td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>Tỷ lệ cơ giới hóa đồng bộ
                            </td>
                            <td>50%
                            </td>
                            <td>70%
                            </td>
                            <td style="font-weight: bold;">Trên 90%
                            </td>
                            <td>100% cơ giới hóa khâu làm đất, thu hoạch; có trên 90% cơ giới hóa khâu gieo sạ, bón phân, phun thuốc.
                            </td>
                            <td>Đạt
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td>Số hộ áp dụng quy trình canh tác bền vững
                            </td>
                            <td>200,000
                            </td>
                            <td>1,000,000
                            </td>
                            <td style="font-weight: bold;"></td>
                            <td>
                            </td>
                            <td>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>8</td>
                            <td>Tỷ lệ thất thoát sau thu hoạch</td>
                            <td>Dưới 10%</td>
                            <td>Dưới 8%
                            </td>
                            <td style="font-weight: bold;">Dưới 10%
                            </td>
                            <td>Trên 90% HTX thu hoạch bằng máy gặt đập liên hợp (giảm thất thoát)
                            </td>
                            <td>Đạt
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>9</td>
                            <td>Rơm tại vùng chuyên canh được thu gom và chế biến tái sử dụng
                            </td>
                            <td>70%
                            </td>
                            <td>100%
                            </td>
                            <td style="font-weight: bold;">79,35% có thu gom rơm ra khỏi đồng ruộng, tuy nhiên còn hạn chế trong mùa mưa
                            </td>
                            <td>79,35% HTX áp dụng biện pháp thu gom rơm ra khỏi đồng ruộng. Tuy nhiên, việc thu gom rơm ra khỏi đồng ruộng không ổn định, đặc biệt là trong mùa mưa (Vụ Hè Thu)
                            </td>
                            <td style="color: red; background-color: yellow;">Chưa đạt
                            </td>
                            <td>Hỗ trợ, tư vấn giúp người dân tiếp cận quy trình, thiết bị công nghệ để sử dụng phụ phẩm trong sản xuất lúa (rơm, rạ, trấu) thành nguyên liệu đầu vào của quy trình sản xuất khác; Phát triển đa dạng sản phẩm từ phụ phẩm như trồng nấm, làm thức ăn cho gia súc, khuôn ương cây giống, khuôn đựng trứng, thùng chứa nông sản, tấm carton, phân hữu cơ…, và marketing tốt cho các sản phẩm bảo vệ môi trường.</td>
                        </tr>
                        <tr>
                            <td>10</td>
                            <td>Giảm phát thải khí nhà kính so với canh tác truyền thống
                            </td>
                            <td>10%
                            </td>
                            <td>10%
                            </td>
                            <td style="font-weight: bold;"></td>
                            <td>
                            </td>
                            <td>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>11</td>
                            <td>Giá trị gia tăng trong chuỗi
                            </td>
                            <td>30%
                            </td>
                            <td>40%
                            </td>
                            <td style="font-weight: bold;"></td>
                            <td>
                            </td>
                            <td>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>12</td>
                            <td>Tỷ suất lợi nhuận người trồng lúa
                            </td>
                            <td>40%
                            </td>
                            <td>50%
                            </td>
                            <td style="font-weight: bold;">Lợi nhuận ròng trung bình 42,7% (Năm 2023)
                            </td>
                            <td>Năng suất tăng (trung bình 6,3 tấn/ha/năm); Giá thị trường tăng
                            </td>
                            <td>Đạt
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>13</td>
                            <td>Lượng gạo CLC và phát thải thấp/ tổng lượng gạo xuất khẩu vùng chuyên canh
                            </td>
                            <td>20%
                            </td>
                            <td>20%
                            </td>
                            <td style="font-weight: bold;">20%
                            </td>
                            <td>
                            </td>
                            <td>Đạt
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <br />
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12">
            <h3 class="text-center" style="text-transform: uppercase;">TÀI LIỆU BÁO CÁO
            </h3>
            <div class="table-responsive">
                <table class="table m-0 align-middle">
                    <thead>
                        <tr>
                            <th style="text-align: center;">STT</th>
                            <th style="text-align: center;">Tiêu đề</th>
                            <th style="text-align: center;">Ngày ghi nhận (Tháng/Ngày/Năm)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of TaiLieuService.list; index as i;">
                            <td style="text-align: center;">
                                {{i + 1}}
                            </td>
                            <td>
                                <a class="link-primary" href="{{item.FileName}}"
                                    target="_blank"><b>{{item.Name}}</b>
                                </a>
                            </td>
                            <td style="text-align: center;">
                                {{item.LastUpdatedDate | date:'MM/dd/yyyy'}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-lg-6 col-sm-12 col-12">
        </div>
    </div>
</div>
<br />	
<app-loading *ngIf="isShowLoading"></app-loading>
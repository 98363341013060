import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';


import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { ThanhVienCoQuanQuanLyDetailComponent } from '../thanh-vien-co-quan-quan-ly-detail/thanh-vien-co-quan-quan-ly-detail.component';
import { ThanhVienNongDanDetailComponent } from '../thanh-vien-nong-dan-detail/thanh-vien-nong-dan-detail.component';
import { ThanhVienLichSuTruyCap } from 'src/app/shared/ThanhVienLichSuTruyCap.model';
import { ThanhVienLichSuTruyCapService } from 'src/app/shared/ThanhVienLichSuTruyCap.service';



@Component({
  selector: 'app-quan-ly',
  templateUrl: './quan-ly.component.html',
  styleUrls: ['./quan-ly.component.css']
})
export class QuanLyComponent implements OnInit {

  ThanhVienCoQuanQuanLyDataSource: MatTableDataSource<any>;
  @ViewChild('ThanhVienCoQuanQuanLySort') ThanhVienCoQuanQuanLySort: MatSort;
  @ViewChild('ThanhVienCoQuanQuanLyPaginator') ThanhVienCoQuanQuanLyPaginator: MatPaginator;

  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  DanhMucTinhThanhID: number = environment.DanhMucTinhThanhID;
  DanhMucQuanHuyenID: number = environment.InitializationNumber;
  DanhMucXaPhuongID: number = environment.InitializationNumber;

  constructor(
    private dialog: MatDialog,

    public NotificationService: NotificationService,

    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    
    
    public ThanhVienService: ThanhVienService,
  ) { }

  ngOnInit(): void {
    this.DanhMucTinhThanhGetToList();
  }

  CoQuanQuanLySearch() {
    this.CoQuanQuanLyGetToList();
  }
  CoQuanQuanLyGetToList() {
    this.isShowLoading = true;
    this.ThanhVienService.baseParameter.ParentID = environment.ThanhVienCoQuanQuanLyID;
    this.ThanhVienService.baseParameter.SearchString = this.ThanhVienService.SearchString;
    this.ThanhVienService.baseParameter.DanhMucTinhThanhID = this.DanhMucTinhThanhID;
    this.ThanhVienService.baseParameter.DanhMucQuanHuyenID = environment.InitializationNumber;
    this.ThanhVienService.baseParameter.DanhMucXaPhuongID = environment.InitializationNumber;
    this.ThanhVienService.GetByParentID_SearchString_DanhMucTinhThanhID_DanhMucQuanHuyenID_DanhMucXaPhuongIDToListAsync().subscribe(
      res => {
        this.ThanhVienService.list002 = (res as ThanhVien[]);
        this.ThanhVienCoQuanQuanLyDataSource = new MatTableDataSource(this.ThanhVienService.list002);
        this.ThanhVienCoQuanQuanLyDataSource.sort = this.ThanhVienCoQuanQuanLySort;
        this.ThanhVienCoQuanQuanLyDataSource.paginator = this.ThanhVienCoQuanQuanLyPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  CoQuanQuanLyAdd(ID: any) {
    this.ThanhVienService.baseParameter.ID = ID;
    this.ThanhVienService.GetByIDAsync().subscribe(
      res => {
        this.ThanhVienService.formData = res as ThanhVien;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ThanhVienCoQuanQuanLyDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.CoQuanQuanLySearch();
        });
      },
      err => {
      }
    );
  }

  DanhMucTinhThanhGetToList() {
    this.isShowLoading = true;
    this.DanhMucTinhThanhService.baseParameter.Active = true;
    this.DanhMucTinhThanhService.GetSQLByThanhVienIDAndActiveToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.list = (res as DanhMucTinhThanh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucTinhThanhService.list001 = this.DanhMucTinhThanhService.list;
        if (this.DanhMucTinhThanhService.list) {
          if (this.DanhMucTinhThanhService.list.length) {
            this.DanhMucTinhThanhID = this.DanhMucTinhThanhService.list[0].ID;
          }
        }        
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  DanhMucTinhThanhFilter(searchString: string) {
    this.DanhMucTinhThanhService.Filter(searchString);
  }



}

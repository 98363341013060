import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ThanhVienPhanQuyenChucNang } from 'src/app/shared/ThanhVienPhanQuyenChucNang.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ThanhVienPhanQuyenChucNangService extends BaseService{

    list: ThanhVienPhanQuyenChucNang[] | undefined;
    list001: ThanhVienPhanQuyenChucNang[] | undefined;
    formData!: ThanhVienPhanQuyenChucNang;     

    displayColumns001: string[] = ['STT', 'Name', 'Active'];   

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ThanhVienPhanQuyenChucNang";
    }

    GetSQLByParentIDToListAsync() {
        let url = this.aPIURL + this.controller + '/GetSQLByParentIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
}


<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-hypnotize fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Hợp tác xã</h2>
            <h6 class="m-0 text-dark fw-light">
                Hợp tác xã
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">           
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ToChuc" data-bs-toggle="tab" href="#ToChuc"
                                    role="tab" aria-controls="ToChuc" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Bước 01: Pháp lý</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucThanhVien" data-bs-toggle="tab"
                                    href="#ToChucThanhVien" role="tab" aria-controls="ToChucThanhVien"
                                    aria-selected="false">
                                    <span class="badge bg-danger" style="font-size: 16px;">Bước 02: Thành
                                        viên</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucHopTac" data-bs-toggle="tab" href="#ToChucHopTac"
                                    role="tab" aria-controls="ToChucHopTac" aria-selected="false">
                                    <span class="badge bg-success" style="font-size: 16px;">Bước 03: Liên kết</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucTieuChuanSanXuat" data-bs-toggle="tab"
                                    href="#ToChucTieuChuanSanXuat" role="tab" aria-controls="ToChucTieuChuanSanXuat"
                                    aria-selected="false">
                                    <span class="badge bg-warning" style="font-size: 16px;">Bước 04: Tiêu
                                        chuẩn</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucSanPham" data-bs-toggle="tab" href="#ToChucSanPham"
                                    role="tab" aria-controls="ToChucSanPham" aria-selected="false">
                                    <span class="badge bg-primary" style="font-size: 16px;">Bước 05: Sản phẩm</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucKhoThietBi" data-bs-toggle="tab"
                                    href="#ToChucKhoThietBi" role="tab" aria-controls="ToChucKhoThietBi"
                                    aria-selected="false">
                                    <span class="badge bg-dark" style="font-size: 16px;">Bước 06: Thiết bị</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucDuAn" data-bs-toggle="tab" href="#ToChucDuAn"
                                    role="tab" aria-controls="ToChucDuAn" aria-selected="false">
                                    <span class="badge bg-info" style="font-size: 16px;">Bước 07: Đề án</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucToaDo" data-bs-toggle="tab" href="#ToChucToaDo"
                                    role="tab" aria-controls="ToChucToaDo" aria-selected="false">
                                    <span class="badge bg-danger" style="font-size: 16px;">Bước 08: Tọa độ</span>
                                </a>
                            </li>
                            <!-- <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucError" data-bs-toggle="tab" href="#ToChucError"
                                    role="tab" aria-controls="ToChucError" aria-selected="false">
                                    <span class="badge bg-success" style="font-size: 16px;">Lỗi địa chỉ</span>
                                </a>
                            </li> -->
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ToChuc" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label class="form-label">Tỉnh thành</label>
                                        <mat-select class="form-control" name="DanhMucTinhThanhID"
                                            (selectionChange)="DanhMucQuanHuyenGetToList()"
                                            [(ngModel)]="DanhMucTinhThanhID">
                                            <input placeholder="Tìm..." class="form-control"
                                                (keyup)="DanhMucTinhThanhFilter($event.target.value)">
                                            <mat-option *ngFor="let item of DanhMucTinhThanhService.list001"
                                                [value]="item.ID">
                                                {{item.Name}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">Quận huyện</label>
                                        <mat-select class="form-control" name="DanhMucQuanHuyenID"
                                            (selectionChange)="DanhMucXaPhuongGetToList()"
                                            [(ngModel)]="DanhMucQuanHuyenID">
                                            <input placeholder="Tìm..." class="form-control"
                                                (keyup)="DanhMucQuanHuyenFilter($event.target.value)">
                                            <mat-option [value]="0">
                                                Tất cả
                                            </mat-option>
                                            <mat-option *ngFor="let item of DanhMucQuanHuyenService.list001"
                                                [value]="item.ID">
                                                {{item.Name}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">Xã phường</label>
                                        <mat-select class="form-control" name="DanhMucXaPhuongID"
                                            [(ngModel)]="DanhMucXaPhuongID" (selectionChange)="ToChucSearch()">
                                            <input placeholder="Tìm..." class="form-control"
                                                (keyup)="DanhMucXaPhuongFilter($event.target.value)">
                                            <mat-option [value]="0">
                                                Tất cả
                                            </mat-option>
                                            <mat-option *ngFor="let item of DanhMucXaPhuongService.list001"
                                                [value]="item.ID">
                                                {{item.Name}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <input [(ngModel)]="ToChucService.SearchString" placeholder="Tìm ..."
                                            class="form-control" (keyup.enter)="ToChucSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <div>
                                            <a class="btn btn-success" title="Tìm" (click)="ToChucSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                            <a class="btn btn-success" title="Thêm mới" (click)="ToChucAdd(0)"><i
                                                    class="bi bi-plus"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Pháp lý
                                            <span *ngIf="ToChucService.list000">({{ToChucService.list000.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucSort="matSort"
                                                    [dataSource]="ToChucDataSource" class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucPaginator.pageSize *
                                                            ToChucPaginator.pageIndex + i + 1}} - [{{element.ID}}]
                                                        </td>
                                                    </ng-container>                                                    
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.Name}}"
                                                                class="link-primary"
                                                                (click)="ToChucAdd(element.ID)"><b>{{element.Name}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <!-- <ng-container matColumnDef="DienThoai">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DienThoai}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DienTichSanXuatHa">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Diện tích
                                                            (ha)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.DienTichSanXuatHa |
                                                            number:
                                                            '1.2-2'}}
                                                        </td>
                                                    </ng-container> -->
                                                    <ng-container matColumnDef="DanhMucTinhThanhName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tỉnh thành
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DanhMucTinhThanhName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucQuanHuyenName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quận huyện
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DanhMucQuanHuyenName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucXaPhuongName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Xã phường
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DanhMucXaPhuongName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Năng lực
                                                            sản xuất
                                                        </th>
                                                        <td mat-cell *matCellDef="let element" style="text-align: center;">
                                                            <a class="btn btn-success"
                                                                (click)="ToChucQuyTrinhSanXuatAdd(element.ID)"
                                                                *ngIf="element.ID>0"><i
                                                                    class="bi bi-arrows-fullscreen"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hồ sơ (In và xuất PDF)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element" style="text-align: center;">
                                                            <!-- <a class="btn btn-success"
                                                                (click)="ToChucExportHopTacXaByIDToHTMLAsync(element)"
                                                                *ngIf="element.ID>0"><i
                                                                    class="bi bi-printer"></i>
                                                            </a> -->
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucService.displayColumns005">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucService.displayColumns005;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucThanhVien" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <label class="form-label">Hợp tác xã</label>
                                        <mat-select class="form-control" name="ToChucID" [(ngModel)]="ToChucID"
                                            (selectionChange)="ToChucThanhVienSearch()">
                                            <input placeholder="Tìm..." class="form-control"
                                                (keyup)="ToChucFilter($event.target.value)">
                                            <mat-option *ngFor="let item of ToChucService.list001" [value]="item.ID">
                                                {{item.Name}} - {{item.DanhMucTinhThanhName}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <input [(ngModel)]="ToChucThanhVienService.SearchString" placeholder="Tìm ..."
                                            class="form-control" (keyup.enter)="ToChucThanhVienSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <div>
                                            <a class="btn btn-success" title="Tìm" (click)="ToChucThanhVienSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-danger">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Thành viên
                                            <span
                                                *ngIf="ToChucThanhVienService.list">({{ToChucThanhVienService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucThanhVienSort="matSort"
                                                    [dataSource]="ToChucThanhVienService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucThanhVienPaginator.pageSize *
                                                            ToChucThanhVienPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucChucDanhID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chức danh
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select" name="element.DanhMucChucDanhID"
                                                                [(ngModel)]="element.DanhMucChucDanhID">
                                                                <option
                                                                    *ngFor="let item of DanhMucChucDanhService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucChungChiID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chứng chỉ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select" name="element.DanhMucChungChiID"
                                                                [(ngModel)]="element.DanhMucChungChiID">
                                                                <option
                                                                    *ngFor="let item of DanhMucChungChiService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Họ tên
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text" placeholder="Họ tên"
                                                                name="Name{{element.ID}}" [(ngModel)]="element.Name">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                style="text-align: right;" placeholder="Điện thoại"
                                                                name="Code{{element.ID}}" [(ngModel)]="element.Code">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NamKinhNghiem">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kinh
                                                            nghiệm
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                style="text-align: right;" placeholder="Kinh nghiệm"
                                                                name="NamKinhNghiem{{element.ID}}"
                                                                [(ngModel)]="element.NamKinhNghiem">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucThanhVienSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucThanhVienDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucThanhVienService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucThanhVienService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucThanhVienPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucHopTac" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <label class="form-label">Hợp tác xã</label>
                                        <mat-select class="form-control" name="ToChucID" [(ngModel)]="ToChucID"
                                            (selectionChange)="ToChucHopTacSearch()">
                                            <input class="form-control" (keyup)="ToChucFilter($event.target.value)">
                                            <mat-option *ngFor="let item of ToChucService.list001" [value]="item.ID">
                                                {{item.Name}} - {{item.DanhMucTinhThanhName}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <input [(ngModel)]="ToChucHopTacService.SearchString" placeholder="Tìm ..."
                                            class="form-control" (keyup.enter)="ToChucHopTacSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <div>
                                            <a class="btn btn-success" title="Tìm" (click)="ToChucHopTacSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-success">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Liên kết
                                            <span *ngIf="ToChucHopTacService.list">({{ToChucHopTacService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucHopTacSort="matSort"
                                                    [dataSource]="ToChucHopTacService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucHopTacPaginator.pageSize *
                                                            ToChucHopTacPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucLienKetID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hình thức
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select" name="element.DanhMucLienKetID"
                                                                [(ngModel)]="element.DanhMucLienKetID">
                                                                <option *ngFor="let item of DanhMucLienKetService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ToChucID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Đối tác
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select" name="element.ToChucID"
                                                                [(ngModel)]="element.ToChucID">
                                                                <option *ngFor="let item of ToChucService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DienTich">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Diện tích
                                                            (ha)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder="0"
                                                                name="DienTich{{element.ID}}"
                                                                [(ngModel)]="element.DienTich">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SanLuong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sản lượng
                                                            (tấn)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder="0"
                                                                name="SanLuong{{element.ID}}"
                                                                [(ngModel)]="element.SanLuong">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NamBatDau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Năm bắt
                                                            đầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                style="text-align: right;" placeholder="0"
                                                                name="NamBatDau{{element.ID}}"
                                                                [(ngModel)]="element.NamBatDau">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucHopTacSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucHopTacDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucHopTacService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucHopTacService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucHopTacPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucTieuChuanSanXuat" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <label class="form-label">Hợp tác xã</label>
                                        <mat-select class="form-control" name="ToChucID" [(ngModel)]="ToChucID"
                                            (selectionChange)="ToChucTieuChuanSanXuatSearch()">
                                            <input class="form-control" (keyup)="ToChucFilter($event.target.value)">
                                            <mat-option *ngFor="let item of ToChucService.list001" [value]="item.ID">
                                                {{item.Name}} - {{item.DanhMucTinhThanhName}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <input [(ngModel)]="ToChucTieuChuanSanXuatService.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="ToChucTieuChuanSanXuatSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <div>
                                            <a class="btn btn-success" title="Tìm"
                                                (click)="ToChucTieuChuanSanXuatSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-success">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Tiêu chuẩn sản xuất
                                            <span
                                                *ngIf="ToChucTieuChuanSanXuatService.list">({{ToChucTieuChuanSanXuatService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucTieuChuanSanXuatSort="matSort"
                                                    [dataSource]="ToChucTieuChuanSanXuatService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucTieuChuanSanXuatPaginator.pageSize *
                                                            ToChucTieuChuanSanXuatPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucTieuChuanSanXuatID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu chuẩn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="element.DanhMucTieuChuanSanXuatID"
                                                                [(ngModel)]="element.DanhMucTieuChuanSanXuatID">
                                                                <option
                                                                    *ngFor="let item of DanhMucTieuChuanSanXuatService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DienTich">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Diện tích
                                                            (ha)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder="0"
                                                                name="DienTich{{element.ID}}"
                                                                [(ngModel)]="element.DienTich">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SanLuong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sản lượng
                                                            (tấn)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder="0"
                                                                name="SanLuong{{element.ID}}"
                                                                [(ngModel)]="element.SanLuong">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucTieuChuanSanXuatSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucTieuChuanSanXuatDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucTieuChuanSanXuatService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucTieuChuanSanXuatService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucTieuChuanSanXuatPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucSanPham" role="tabpanel">
                                <div class="row gx-4">
                                    <label class="form-label">Hợp tác xã</label>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <mat-select class="form-control" name="ToChucID" [(ngModel)]="ToChucID"
                                            (selectionChange)="ToChucSanPhamSearch()">
                                            <input class="form-control" (keyup)="ToChucFilter($event.target.value)">
                                            <mat-option *ngFor="let item of ToChucService.list001" [value]="item.ID">
                                                {{item.Name}} - {{item.DanhMucTinhThanhName}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <input [(ngModel)]="ToChucSanPhamService.SearchString" placeholder="Tìm ..."
                                            class="form-control" (keyup.enter)="ToChucSanPhamSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <div>
                                            <a class="btn btn-success" title="Tìm" (click)="ToChucSanPhamSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-primary">
                                    <div class="card-header">
                                        <h5 class="card-title text-white bg-primary">Sản phẩm + Dịch vụ
                                            <span
                                                *ngIf="ToChucSanPhamService.list">({{ToChucSanPhamService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body bg-primary">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucSanPhamSort="matSort"
                                                    [dataSource]="ToChucSanPhamService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucSanPhamPaginator.pageSize *
                                                            ToChucSanPhamPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucSanPhamID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sản phẩm +
                                                            Dịch vụ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select" name="element.DanhMucSanPhamID"
                                                                [(ngModel)]="element.DanhMucSanPhamID">
                                                                <option *ngFor="let item of DanhMucSanPhamService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucSanPhamSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucSanPhamDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucSanPhamService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucSanPhamService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucSanPhamPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucKhoThietBi" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <label class="form-label">Hợp tác xã</label>
                                        <mat-select class="form-control" name="ToChucID" [(ngModel)]="ToChucID"
                                            (selectionChange)="ToChucKhoThietBiSearch()">
                                            <input class="form-control" (keyup)="ToChucFilter($event.target.value)">
                                            <mat-option *ngFor="let item of ToChucService.list001" [value]="item.ID">
                                                {{item.Name}} - {{item.DanhMucTinhThanhName}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <input [(ngModel)]="ToChucKhoThietBiService.SearchString" placeholder="Tìm ..."
                                            class="form-control" (keyup.enter)="ToChucKhoThietBiSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <div>
                                            <a class="btn btn-success" title="Tìm" (click)="ToChucKhoThietBiSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-dark">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Thiết bị - Cơ giới
                                            <span
                                                *ngIf="ToChucKhoThietBiService.list">({{ToChucKhoThietBiService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucKhoThietBiSort="matSort"
                                                    [dataSource]="ToChucKhoThietBiService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucKhoThietBiPaginator.pageSize *
                                                            ToChucKhoThietBiPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucThietBiID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Thiết bị
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select" name="element.DanhMucThietBiID"
                                                                [(ngModel)]="element.DanhMucThietBiID">
                                                                <option *ngFor="let item of DanhMucThietBiService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoLuong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Số lượng
                                                            (cái | m2)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder="0"
                                                                name="SoLuong{{element.ID}}"
                                                                [(ngModel)]="element.SoLuong">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucKhoThietBiSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucKhoThietBiDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucKhoThietBiService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucKhoThietBiService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucKhoThietBiPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucDuAn" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <label class="form-label">Hợp tác xã</label>
                                        <mat-select class="form-control" name="ToChucID" [(ngModel)]="ToChucID"
                                            (selectionChange)="ToChucDuAnSearch()">
                                            <input class="form-control" (keyup)="ToChucFilter($event.target.value)">
                                            <mat-option *ngFor="let item of ToChucService.list001" [value]="item.ID">
                                                {{item.Name}} - {{item.DanhMucTinhThanhName}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <input [(ngModel)]="ToChucDuAnService.SearchString" placeholder="Tìm ..."
                                            class="form-control" (keyup.enter)="ToChucDuAnSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <div>
                                            <a class="btn btn-success" title="Tìm" (click)="ToChucDuAnSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Đề án
                                            <span *ngIf="ToChucDuAnService.list">({{ToChucDuAnService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucDuAnSort="matSort"
                                                    [dataSource]="ToChucDuAnService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucDuAnPaginator.pageSize *
                                                            ToChucDuAnPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucDuAnID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Đề án
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select" name="element.DanhMucDuAnID"
                                                                [(ngModel)]="element.DanhMucDuAnID">
                                                                <option *ngFor="let item of DanhMucDuAnService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DienTich">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Diện tích
                                                            (ha)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder="0"
                                                                name="DienTich{{element.ID}}"
                                                                [(ngModel)]="element.DienTich">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SanLuong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sản lượng
                                                            (tấn)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder="0"
                                                                name="SanLuong{{element.ID}}"
                                                                [(ngModel)]="element.SanLuong">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucKhoThietBiSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucKhoThietBiDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucDuAnService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucDuAnService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucDuAnPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucToaDo" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <label class="form-label">Hợp tác xã</label>
                                        <mat-select class="form-control" name="ToChucID" [(ngModel)]="ToChucID"
                                            (selectionChange)="ToChucToaDoSearch()">
                                            <input class="form-control" (keyup)="ToChucFilter($event.target.value)">
                                            <mat-option *ngFor="let item of ToChucService.list001" [value]="item.ID">
                                                {{item.Name}} - {{item.DanhMucTinhThanhName}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <input [(ngModel)]="ToChucToaDoService.SearchString" placeholder="Tìm ..."
                                            class="form-control" (keyup.enter)="ToChucToaDoSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <div>
                                            <a class="btn btn-success" title="Tìm" (click)="ToChucToaDoSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                            <a class="btn btn-danger" title="Tìm"
                                                (click)="ToChucToaDoDeleteItemsByListID()"><i
                                                    class="bi bi-trash"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-danger">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Tọa độ
                                            <span *ngIf="ToChucToaDoService.list">({{ToChucToaDoService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucToaDoSort="matSort"
                                                    [dataSource]="ToChucToaDoService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucToaDoPaginator.pageSize *
                                                            ToChucToaDoPaginator.pageIndex + i + 1}} | {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KinhDo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kinh độ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Kinh độ" name="KinhDo{{element.ID}}"
                                                                [(ngModel)]="element.KinhDo">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ViDo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Vĩ độ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text" placeholder="Vĩ độ"
                                                                name="ViDo{{element.ID}}" [(ngModel)]="element.ViDo">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nông hộ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Nông hộ" name="Name{{element.ID}}"
                                                                [(ngModel)]="element.Name">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Điện thoại" name="Code{{element.ID}}"
                                                                [(ngModel)]="element.Code">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sản phẩm
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Sản phẩm" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SortOrder">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sắp xếp
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder="0"
                                                                name="SortOrder{{element.ID}}"
                                                                [(ngModel)]="element.SortOrder">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Trụ sở
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucToaDoSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucToaDoDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucToaDoService.displayColumns002">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucToaDoService.displayColumns002;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucToaDoPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucError" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucService.SearchString" placeholder="Tìm ..."
                                            class="form-control" (keyup.enter)="ToChucErrorSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" (click)="ToChucErrorSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-success">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Lỗi địa chỉ
                                            <span *ngIf="ToChucService.list0001">({{ToChucService.list0001.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucErrorSort="matSort"
                                                    [dataSource]="ToChucErrorDataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucErrorPaginator.pageSize *
                                                            ToChucErrorPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã số thuế
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Code}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.Name}}"
                                                                class="link-primary"
                                                                (click)="ToChucAdd(element.ID)"><b>{{element.Name}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DienThoai">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DienThoai}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DienTichSanXuatHa">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Diện tích
                                                            (ha)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.DienTichSanXuatHa |
                                                            number:
                                                            '1.2-2'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucTinhThanhName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tỉnh thành
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DanhMucTinhThanhName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucQuanHuyenName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quận huyện
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DanhMucQuanHuyenName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucXaPhuongName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Xã phường
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DanhMucXaPhuongName}}
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucErrorPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>
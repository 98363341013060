import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';

@Component({
  selector: 'app-thanh-vien-thong-tin',
  templateUrl: './thanh-vien-thong-tin.component.html',
  styleUrls: ['./thanh-vien-thong-tin.component.css']
})
export class ThanhVienThongTinComponent implements OnInit {

  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;

  constructor(
    public NotificationService: NotificationService,
    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,

    public ThanhVienService: ThanhVienService,
  ) { }

  ngOnInit(): void {
    this.DanhMucTinhThanhGetToList();
    this.GetByQueryString();
  }
  ThanhVienSave() {
    this.isShowLoading = true;
    this.ThanhVienService.formData = this.ThanhVienService.formDataLogin;
    this.ThanhVienService.SaveAsync().subscribe(
      res => {
        this.ThanhVienService.formDataLogin = res as ThanhVien;
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  GetByQueryString() {
    this.isShowLoading = true;
    this.ThanhVienService.GetLogin();
    if (this.ThanhVienService.formDataLogin) {
      if (this.ThanhVienService.formDataLogin.ID > 0) {
        this.ThanhVienService.baseParameter.ID = this.ThanhVienService.formDataLogin.ID;
        this.ThanhVienService.GetByIDAsync().subscribe(res => {
          this.ThanhVienService.formDataLogin = res as ThanhVien;
          this.isShowLoading = false;
        },
          err => {
            this.NotificationService.warn(environment.SaveNotSuccess);
          }
        );
      }
    }
  }


  DanhMucTinhThanhGetToList() {
    if (this.DanhMucTinhThanhService.list.length == 0) {
      this.isShowLoading = true;
      this.DanhMucTinhThanhService.baseParameter.Active = true;
      this.DanhMucTinhThanhService.GetByActiveToListAsync().subscribe(
        res => {
          this.DanhMucTinhThanhService.list = (res as DanhMucTinhThanh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.DanhMucTinhThanhService.list001 = this.DanhMucTinhThanhService.list;
          this.DanhMucQuanHuyenGetToList();
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
  }
  DanhMucQuanHuyenGetToList() {
    this.isShowLoading = true;
    this.DanhMucQuanHuyenService.baseParameter.ParentID = this.ThanhVienService.formDataLogin.DanhMucTinhThanhID;
    this.DanhMucQuanHuyenService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucQuanHuyenService.list = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucQuanHuyenService.list001 = this.DanhMucQuanHuyenService.list;
        this.DanhMucXaPhuongGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongGetToList() {
    this.isShowLoading = true;
    this.DanhMucXaPhuongService.baseParameter.ParentID = this.ThanhVienService.formDataLogin.DanhMucQuanHuyenID;
    this.DanhMucXaPhuongService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucXaPhuongService.list = (res as DanhMucXaPhuong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucXaPhuongService.list001 = this.DanhMucXaPhuongService.list;
        
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucTinhThanhFilter(searchString: string) {
    this.DanhMucTinhThanhService.Filter(searchString);
  }
  DanhMucQuanHuyenFilter(searchString: string) {
    this.DanhMucQuanHuyenService.Filter(searchString);
  }
  DanhMucXaPhuongFilter(searchString: string) {
    this.DanhMucXaPhuongService.Filter(searchString);
  }
}
